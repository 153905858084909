import axios from 'axios';
import {message} from "ant-design-vue";

import router from '@/router';
import storage from './storage';


const service = axios.create({
    baseURL: 'https://vsl-api.hsientang.com'
});


service.interceptors.request.use(config => {
    config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        ...config.headers
    };

    const token = storage.getToken();
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


service.interceptors.response.use(response => {
   return response;
}, error => {
    const data = error.response.data;
    if (data['errcode'] === 1001) {
        message.warning('登录过期，请重新登录。');
        storage.removeToken();
        storage.removeUserInfo();
        router.push('/account/login/');
    } else {
        message.error(data['errmsg']);
    }
    return Promise.reject(error);
});


export default service;
